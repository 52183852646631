import React, {Component} from 'react'

import Layout from '../layout';
import SEO from '../seo';
import fbLogo from '../../assets/images/RRSS-fb.png';
import twLogo from '../../assets/images/RRSS-tw.png';
import BackgroundImage from 'gatsby-background-image';



class PostPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
          ssrDone: false,
          twLink: null,
          fbLink: null
        }
    }

    renderShareButtons = () => {
        if (this.state.ssrDone) {
            return (
                <div className={"post-content"}>
                    <a href={this.state.fbLink}
                        className="rrss-link"
                        target="_blank"
                        rel="noreferrer">
                        <img src={fbLogo} className={"rrss-img"} alt="Facebook"/>
                    </a>
                    <a href={this.state.twLink}
                        className="rrss-link"
                        target="_blank"
                        rel="noreferrer">
                        <img src={twLogo} className={"rrss-img"} alt="Twitter"/>
                    </a>
                </div>
            )
        }
    }

    render() {
        const image = this.props.data.featuredImage.node.localFile.childImageSharp.fluid;
        const title = this.props.data.title;
        const excerpt = this.props.data.excerpt;
        const content = this.props.data.content;
        const category = this.props.data.categories.nodes[0].name;
        const href = this.props.data.uri;
        const autorImg = this.props.data.author.node.customProfileImage || this.props.data.author.node.avatar.url;
        const authorName = this.props.data.author.node.name;
        const autorDescription = this.props.data.author.node.description;
        const authorTwitter = this.props.data.author.node.twitter;
        const copete = this.props.data.copete.copete;
        var authors;
        if (this.props.data.autorxs && this.props.data.autorxs.authors){
            // https://stackoverflow.com/a/49289042
            authors = this.props.data.autorxs.authors.map(a => a.name).join(', ').replace(/, ([^,]*)$/, ' y $1')
        } else {
            authors = this.props.data.author.node.name;
        }

        return (
            <Layout>
                <SEO title={title}
                    description={excerpt.replace(/(<([^>]+)>)/gi, "")}
                    image={{src: image.src, height: 600, width: 800}}
                    author={authorName}
                    author_twitter={authorTwitter}/>
                <BackgroundImage className={"post-header"} fluid={image}>
                    <span className={`copete`}>{copete}</span>
                    <a className={"post-header-title"} href={href} >{title}</a>
                    <div className={"post-home-authorname"}>Por {authors}</div>
                </BackgroundImage>
                <div className={"post-home-category"}>{category}</div>
                {/*<div className={"post-header-info"}>
                    <Box width={{ base: "100vw"}}>
                        <div className={"post-home-category category-img-post"}>{category}</div>
                        <Box 
                            className={"post-home-autor-img autor-img-post"}
                            backgroundImage={`url(${autorImg})`}></Box>
                    </Box>
                </div>*/}

                <div className={"post-excerpt post-content"} dangerouslySetInnerHTML={{ __html: excerpt }}/>
                {this.renderShareButtons()}
                <div className={"post-content"} dangerouslySetInnerHTML={{ __html: content }}/>

                <div className={"post-author-info"}>
                    <img src={autorImg} className={"post-author-info-img"} alt="Foto del autor"/>

                    <div className={"author-info-col"}>
                        <span className={"author-info-name"}>{authorName}</span>
                        <br/>
                        <br/>
                        <span className={"author-info-description"} dangerouslySetInnerHTML={{ __html: autorDescription }}/>
                    </div>
                </div>

            </Layout>
        );
    }

    componentDidMount(){
        this.setState({twLink: `https://twitter.com/intent/tweet?text=%22${this.props.data.title}%22%20${window.location}`});
        this.setState({fbLink: `https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=${window.location}`});
        this.setState({ssrDone: true})
    }

}

export default PostPage;
