import React from "react"
import { graphql } from "gatsby"
import PostPage from "../../components/post-page";

export default ({ data }) => <PostPage data={data.page} />

export const query = graphql`
  query post($id: String!, $nextPage: String, $previousPage: String) {
    page: wpPost(id: { eq: $id }) {
      title
      content
      uri
      featuredImage {
        node {
          sourceUrl
          localFile {
            childImageSharp {
              id
              fluid(maxWidth: 1920, maxHeight: 1080) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      },
      categories {
        nodes {
          id
          name
          slug
        }
      }
      author {
        node {
          id
          name
          description
          customProfileImage
          avatar {
            url
          }
          instagram
          facebook
          twitter
        }
      }
      autorxs {
        authors {
          id
          name
          description
          avatar {
            url
          }
          instagram
          facebook
          twitter
        }
      }
      copete {
        copete
      }
      excerpt
    }

    nextPage: wpPost(id: { eq: $nextPage }) {
      title
      uri
    }

    previousPage: wpPost(id: { eq: $previousPage }) {
      title
      uri
    }
  }
`
